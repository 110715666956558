<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="6">
                <v-card class="pa-0">
                    <v-toolbar short flat :style="cardTitleBarStyle">
                        <v-toolbar-title :style="cardTitleBarTextStyle">Search nameserver groups</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark :to="{ name: 'system-create-nameserver-group' }">
                            <font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text v-if="Array.isArray(nameserverGroupList) && nameserverGroupList.length === 0">
                        No nameserver groups.
                    </v-card-text>
                    <v-list dense v-if="Array.isArray(nameserverGroupList) && nameserverGroupList.length > 0">
                        <v-list-item v-for="(nameserverGroup, idx) in nameserverGroupList" :key="idx" @click="selectNameserverGroup(nameserverGroup.id)">
                            <v-list-item-content>
                                <v-list-item-title>{{ nameserverGroup.label }}</v-list-item-title>
                                <!-- <v-list-item-subtitle>{{ nameserver.ipaddr }}</v-list-item-subtitle> -->
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    data: () => ({
        nameserverGroupList: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            cardTitleBarStyle: 'cardTitleBarStyle',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
        }),
    },
    watch: {
        focus() {
            this.loadNameserverGroupList();
        },
    },
    methods: {
        init() {
            this.loadNameserverGroupList();
        },
        async loadNameserverGroupList() {
            try {
                this.$store.commit('loading', { loadNameserverGroupList: true });
                const response = await this.$client.main().nameserverGroup.search();
                if (response?.list) {
                    this.nameserverGroupList = response.list;
                }
            } catch (err) {
                console.error('failed to load nameserver group list', err);
            } finally {
                this.$store.commit('loading', { loadNameserverGroupList: false });
                this.isViewReady = true;
            }
        },
        async loadServiceSettingsList() {
            try {
                this.$store.commit('loading', { loadServiceSettingsList: true });
                const response = await this.$client.main().system.getSettingsList();
                if (response?.list) {
                    const settingsMap = {};
                    response.list.forEach((item) => {
                        settingsMap[item.name] = item.content;
                    });
                    this.registrationMode = settingsMap.registration_mode ?? 'closed';
                }
            } catch (err) {
                console.error('failed to load service settings', err);
            } finally {
                this.$store.commit('loading', { loadServiceSettingsList: false });
                this.isViewReady = true;
            }
        },
        async submitForm() {
            console.log('submitForm');
        },
        selectNameserverGroup(id) {
            this.$router.push({ name: 'system-edit-nameserver-group', query: { id } });
        },
    },
    mounted() {
        this.init();
    },
};
</script>
